<template>
  <el-menu
    :default-active="$route.name"
    class="el-menu-demo"
    mode="horizontal"
    @select="handleSelect"
    background-color="#545c64"
    text-color="#fff"
    active-text-color="#ffd04b"
  >
    <div class="logo-group">
      <el-image class="logo-img" :src="require('../assets/logo.png')"></el-image>
      <h2 class="logo-name">碰她互娱</h2>
    </div>
    <div class="menu-item-list">
      <el-menu-item index="index">
        <span>首页</span>
      </el-menu-item>
      <el-menu-item index="download">
        <span>下载</span>
      </el-menu-item>
      <el-menu-item index="popularizLaw">
        <span>普法</span>
      </el-menu-item>
      <el-menu-item index="recharge">
        <span>充值</span>
      </el-menu-item>
      <el-menu-item index="new">
        <span>资讯</span>
      </el-menu-item>
      <el-menu-item index="aboutUs">
        <span>关于我们</span>
      </el-menu-item>
      <div class="login-card">
        <el-avatar icon="el-icon-user-solid" shape="square" :size="40" src></el-avatar>
        <div>
          <el-button
            v-if="username"
            type="text"
            class="login-btn"
            @click="handleLoginAndOut(username)"
            >{{ username }}</el-button
          >
          <el-button
            v-if="!username"
            type="text"
            class="login-btn"
            @click="handleLoginAndOut(username)"
            >登录 | 注册</el-button
          >
        </div>
      </div>
    </div>
    <el-dialog title width="400px" :visible.sync="dialogVisible">
      <el-form :rules="rules" ref="loginForm" :model="loginForm">
        <h3 v-if="dialogType == 'login'" class="loginTitle">欢迎登录</h3>
        <h3 v-if="dialogType == 'signup'" class="loginTitle">注册账号</h3>
        <el-form-item prop="userId">
          <el-input v-model="loginForm.userId" placeholder="请输入用户号"></el-input>
        </el-form-item>
        <el-form-item prop="trueName">
          <el-input v-model="loginForm.trueName" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item prop="idCode">
          <el-input v-model="loginForm.idCode" placeholder="请输入身份证号"></el-input>
        </el-form-item>
        <el-button
          v-if="dialogType == 'login'"
          type="primary"
          style="width: 100%"
          @click="submitLogin"
          >登录</el-button
        >
        <el-button
          v-if="dialogType == 'signup'"
          type="primary"
          style="width: 100%"
          @click="signUp"
          >注册</el-button
        >
        <el-button
          v-if="dialogType == 'login'"
          type="text"
          style="position: relative; left: 30%"
          @click="dialogType = 'signup'"
          >没有账号？去注册</el-button
        >
        <el-button
          v-if="dialogType == 'signup'"
          type="text"
          style="position: relative; left: 30%"
          @click="dialogType = 'login'"
          >已有账号？去登录</el-button
        >
      </el-form>
    </el-dialog>
  </el-menu>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      dialogType: "login",
      username: undefined,
      captchaUrl: "",
      loginForm: {
        userId: "",
        trueName: "",
        idCode: "",
      },
      rules: {
        userId: [
          { required: true, message: "请输入用户号", trigger: "blur" },
          // { min: 5, max: 14, message: "长度在 5 到 14 个字符", trigger: "blur" }
        ],
        trueName: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
          // { min: 6, message: "密码长度要大于6", trigger: "blur" }
        ],
        idCode: [{ required: true, message: "请输入身份证号", trigger: "blur" }],
      },
    };
  },
  created() {
    const daoUserInfo = JSON.parse(localStorage.getItem("daoUserInfo"));
    const ptUserInfo = JSON.parse(localStorage.getItem("ptUserInfo"));
    if (ptUserInfo && !ptUserInfo.logout) this.username = ptUserInfo.trueName;
    if (daoUserInfo) this.username = daoUserInfo.nicknamec;
    this.$bus.$on("openLogin", () => {
      this.dialogVisible = true;
    });
  },
  beforeDestroy() {
    this.$bus.$off("openLogin");
  },
  methods: {
    handleSelect(url) {
      this.$router.push({ path: `/${url}` });
    },
    handleLoginAndOut(username) {
      if (username) {
        this.$confirm("确定登出？", "提示", {
          type: "warning",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(() => {
            this.username = null;
            localStorage.removeItem("daoUserInfo");
            let obj = JSON.parse(localStorage.getItem("ptUserInfo"));
            if (obj) {
              localStorage.setItem(
                "ptUserInfo",
                JSON.stringify({ ...obj, logout: true })
              );
            }
            this.$message({
              type: "success",
              message: "登出成功!",
            });
          })
          .catch(() => {
            return;
          });
      } else {
        this.dialogVisible = true;
      }
    },
    submitLogin() {
      this.$refs.loginForm.validate((valid) => {
        let { userId, trueName, idCode } = this.loginForm;
        if (valid) {
          const ptUserInfo = JSON.parse(localStorage.getItem("ptUserInfo")) || {
            userId: undefined,
            trueName: undefined,
            idCode: undefined,
          };
          if (
            userId == ptUserInfo.userId &&
            trueName == ptUserInfo.trueName &&
            idCode == ptUserInfo.idCode
          ) {
            localStorage.setItem("ptUserInfo", JSON.stringify({ ...ptUserInfo, logout: false }));
            this.dialogVisible = false;
            this.$message.success("登录成功");
            location.reload();
            return;
          }
          this.axios
            // .get(`http://127.0.0.1:8090/api/user/identity/logon/${userId}/${trueName}/${idCode}`)
            .get(
              `http://146.56.202.110:8090/api/user/identity/logon/${userId}/${trueName}/${idCode}`
            )
            .then((res) => {
              if (!res.data) {
                return this.$message.error("用户信息错误");
              } else {
                let userInfo = res.data.data.userInfo;
                this.dialogVisible = false;
                localStorage.setItem("daoUserInfo", JSON.stringify(userInfo));
                this.$message.success("登录成功");
                location.reload();
              }
            });
        }
      });
    },
    signUp() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          localStorage.setItem(
            "ptUserInfo",
            JSON.stringify({ ...this.loginForm, logout: false })
          );
          this.dialogVisible = false;
          this.$message.success("注册成功");
          location.reload();
        }
      });
    },
  },
};
</script>

<style scoped>
.el-menu-demo {
  width: 80%;
  min-width: 1100px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  /* border-radius: 0 0 10px 10px; */
  background: rgba(20, 22, 24, 0.8) !important;
  height: 100px;
}

.el-menu.el-menu--horizontal {
  border: 0;
}

.logo-group {
  width: 40%;
  min-width: 100px;
  display: flex;
  align-items: center;
}

.logo-img {
  height: 70px;
  margin-left: 20px;
}

.logo-name {
  font-size: 40px;
  font-weight: 400;
  color: rgb(255, 255, 255);
  margin: 0 20px;
}

.menu-item-list {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  min-width: 60%;
  margin: 20px 0 10px 0;
}

.el-menu-item {
  padding: 0 30px;
  height: 40px;
  line-height: 40px;
  color: rgb(255, 255, 255);
  transform: skew(-20deg);
  -ms-transform: skew(-20deg);
  -moz-transform: skew(-20deg);
  -webkit-transform: skew(-20deg);
  -o-transform: skew(-20deg);
  background: rgb(20, 22, 24) !important;
}

.el-menu-item > span {
  display: inline-block;
  transform: skew(20deg);
  -ms-transform: skew(20deg);
  -moz-transform: skew(20deg);
  -webkit-transform: skew(20deg);
  -o-transform: skew(20deg);
}

.el-menu-item:hover {
  color: rgb(0, 0, 0) !important;
  border-bottom-color: rgb(255 178 0) !important;
  background-color: rgb(255 178 0) !important;
}

.el-menu-item.is-active {
  color: rgb(0, 0, 0) !important;
  border-bottom-color: rgb(255 178 0) !important;
  background-color: rgb(255 178 0) !important;
}

.login-card {
  display: flex;
  margin: 0 20px;
  vertical-align: middle;
  min-width: 120px;
}

.el-avatar {
  border-radius: 0 !important;
  background: rgb(255 178 0);
  color: rgb(0, 0, 0);
}

.login-btn {
  min-width: 80px;
  color: rgb(255, 255, 255);
  border: 0;
  border-top: 2px solid rgb(255 178 0);
  border-radius: 0;
}

.login-btn:hover,
.login-btn:focus,
.login-btn.is-active,
.login-btn:active {
  color: rgb(255 178 0);
  border-top: 2px solid rgb(255 178 0);
}

.loginTitle {
  margin: 0px auto 48px auto;
  text-align: center;
  font-size: 40px;
}

.messageClass {
  z-index: 3000 !important;
}
</style>
