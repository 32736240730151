import Vue from "vue";
import VueRouter from "vue-router";
import Router from "vue-router";

const loadComponent = (componentName) => () => import(`@/views/${componentName}.vue`);
const routes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/index",
    name: "index",
    component: loadComponent("Index"),
  },
  {
    path: "/download",
    name: "download",
    component: loadComponent("Download"),
  },
  {
    path: "/popularizLaw",
    name: "popularizLaw",
    component: loadComponent("PopularizLaw"),
  },
  {
    path: "/recharge",
    name: "recharge",
    component: loadComponent("Recharge"),
  },
  {
    path: "/new",
    name: "new",
    component: loadComponent("New"),
  },
  {
    path: "/aboutUs",
    name: "aboutUs",
    component: loadComponent("AboutUs"),
  },
  {
    path: "/content",
    name: "content",
    component: loadComponent("Content"),
  },
  {
    path: "/agreement",
    name: "agreement",
    component: loadComponent("agreement"),
  },
  {
    path: "/wardship",
    name: "wardship",
    component: loadComponent("wardship"),
  },
  {
    path: "/process",
    name: "process",
    component: loadComponent("process"),
  },
  {
    path: "/serve",
    name: "serve",
    component: loadComponent("serve"),
  },
];
const router = new VueRouter({
    //mode: "history",
    base: '/', //base: '/pengtaGame',
  routes,
});
Vue.use(Router);
export default router;
